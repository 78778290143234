import { createRouter, createWebHistory } from "vue-router";
// import axios from "axios";
import store from "../store";

import auth from '../middleware/auth'
import middlewarePipeline from './middlewarePipeline'

import UserLayout from "../layout/UserLayout.vue";
import AdminLayout from "../layout/AdminLayout.vue";

const routes = [
  // ************** USER Route **************
  {
    path: "/",
    name: "HomePage",
    component: UserLayout,
    meta: {
      middleware: [
          auth
      ],
      portal: 'User'
    },
    children: [
      {
        path: "",
        name: "ViewPage",
        component: () => import("../views/user/Home.vue"),
        meta: { authRequired: false, title: 'Home | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/all-enquiries",
        name: "AllEnquiriesPage",
        component: () => import("../views/user/AllEnquiries.vue"),
        meta: { authRequired: false, title: 'Travel Quests | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/contact",
        name: "ContactPage",
        component: () => import("../views/user/Contact.vue"),
        meta: { authRequired: false, title: 'Contact | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/about",
        name: "AboutPage",
        component: () => import("../views/user/About.vue"),
        meta: { authRequired: false, title: 'About | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/add-enquiry",
        name: "AddEnquiryPage",
        component: () => import("../views/user/AddEnquiry.vue"),
        meta: { authRequired: false, title: 'Add Enquiry | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/edit-enquiry-:id",
        name: "EditEnquiryPage",
        component: () => import("../views/user/profile/EditEnquiry.vue"),
        meta: { authRequired: false, title: 'Edit Enquiry | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/destination-:id",
        name: "DestinationPage",
        component: () => import("../views/user/Destination.vue"),
        meta: { authRequired: false, title: 'Destination | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/add-response-:id",
        name: "AddResponsePage",
        component: () => import("../views/user/AddResponse.vue"),
        meta: { authRequired: false, title: 'Add Response | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/all-responses-:id",
        name: "AllResponsesPage",
        component: () => import("../views/user/AllResponses.vue"),
        meta: { authRequired: false, title: 'All Responses | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/response-details-:id",
        name: "ResponseDetailsPage",
        component: () => import("../views/user/ResponseDetails.vue"),
        meta: { authRequired: false, title: 'Response Details | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/Responder-profile-:id",
        name: "ResponderProfilePage",
        component: () => import("../views/user/ResponderProfile.vue"),
        meta: { authRequired: false, title: 'Responder Profile | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/profile",
        name: "ProfilePage",
        component: () => import("../views/user/profile/Profile.vue"),
        meta: { authRequired: true, title: 'Profile | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/edit-profile-:id",
        name: "EditProfilePage",
        component: () => import("../views/user/profile/EditProfile.vue"),
        meta: { authRequired: true, title: 'Edit Profile | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/my-enquiries",
        name: "MyQueryPage",
        component: () => import("../views/user/profile/MyQuery.vue"),
        meta: { authRequired: false, title: 'My Query | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/my-response",
        name: "MyResponsePage",
        component: () => import("../views/user/profile/MyResponse.vue"),
        meta: { authRequired: false, title: 'My Response | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/edit-response-:id",
        name: "EditResponsePage",
        component: () => import("../views/user/profile/EditResponse.vue"),
        meta: { authRequired: false, title: 'Edit Response | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/blogs",
        name: "UserBlogPage",
        component: () => import("../views/user/blog/Blog.vue"),
        meta: { authRequired: false, title: 'Blogs | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/blog-details-:id",
        name: "UserBlogDetailsPage",
        component: () => import("../views/user/blog/BlogDetails.vue"),
        meta: { authRequired: false, title: 'Blog Details| Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
      {
        path: "/my-transactions",
        name: "MyTransactionPage",
        component: () => import("../views/user/profile/MyTransactions.vue"),
        meta: { authRequired: false, title: 'My Transactions | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
      },
    ]
  },

  // {
  //   path: "/add-enquiryy",
  //   name: "AddEnquiryPage",
  //   component: () => import("../views/user/AddEnquiry.vue"),
  //   meta: { authRequired: false, title: 'Add Enquiry | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
  // },


    // **************** Admin Route **************
    {
      path: "/",
      name: "DashboardPage",
      component: AdminLayout,
      // meta: {
      //   middleware: [
      //       auth
      //   ],
      //   portal: 'Admin'
      // },
      children: [
        {
          path: "admin",
          name: "AdminViewPage",
          component: () => import("../views/admin/Dashboard.vue"),
          meta: {authRequired: true, title: 'Dashboard | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
        },
        {
          path: "/members",
          name: "MembersPage",
          component: () => import("../views/admin/Member.vue"),
          meta: {authRequired: true, title: 'Member | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
        },
        {
          path: "/travel",
          name: "TravelPage",
          component: () => import("../views/admin/Travel.vue"),
          meta: {authRequired: true, title: 'Travel | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
        },
        {
          path: "/transactions",
          name: "TransactionsPage",
          component: () => import("../views/admin/Transactions.vue"),
          meta: {authRequired: true, title: 'Transactions | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
        },
        {
          path: "/newsletter",
          name: "NewsletterPage",
          component: () => import("../views/admin/Newsletter.vue"),
          meta: {authRequired: true, title: 'Newsletter | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
        },
        {
          path: "/blog",
          name: "BlogPage",
          component: () => import("../views/admin/Blog.vue"),
          meta: {authRequired: true, title: 'Blog | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
        },
      ]
    },


    // login register user
    // login
    {
      path: "/login-user",
      name: "LoginPage",
      component: () => import("../views/user/Login.vue"),
      meta: { title: 'Login Page | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
    },
    //register
    {
      path: "/register-user",
      name: "RegisterPage",
      component: () => import("../views/user/Register.vue"),
      meta: { title: 'Register Page | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
    },

    //login and register company
    //login
    {
    path: "/login-admin",
    name: "AdminLoginPage",
    component: () => import("../views/admin/Login.vue"),
    meta: { title: 'Login Page | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
    },
    // //register
    // {
    //   path: "/register-company",
    //   name: "CompanyRegisterPage",
    //   component: () => import("../views/company/Register.vue"),
    //   meta: { title: 'Register Page | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
    // },

    // ********** success for subscription **********
    // {
    //   path: "/success",
    //   name: "success",
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../components/partials/success.vue"),
    //   meta: { title: 'Success' },
    // },
    // // ********** error for subscription **********
    // {
    //   path: "/error",
    //   name: "error",
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../components/partials/error.vue"),
    //   meta: { title: 'Error' },
    // },

    // ********** Reset password **********
    {
      path: "/reset_password-:id",
      name: "resetPassword",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/user/ResetPassword.vue"),
      meta: { title: 'Reset Password | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
    },
    {
      path: "/terms-conditions",
      name: "termsAndConditions",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/user/TermsOfUse.vue"),
      meta: { title: 'Terms Of Use | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
    },
    {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/user/PrivacyPolicy.vue"),
      meta: { title: 'Privacy Policy | Aero Miles | Unlock the Best Award Flights with Miles & Points.' },
    },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Aero Miles';

  window.scrollTo(0, 0);

  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware
  const context = {
      to,
      from,
      next,
      store
  }

   return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })
});

export default router;
